import { Admin, Resource, combineDataProviders } from "react-admin";
import ConceptDataProvider from "./Concepts/ConceptDataProvider";
import AccessAreaDataProvider from "./AccessAreas/AccessAreaDataProvider";
import UserDataProvider from "./Users/UserDataProvider";
import TenantTypeDataProvider from "./TenantTypes/TenantTypeDataProvider";
import TenantDataProvider from "./Tenants/TenantDataProvider";
import PermissionDataProvider from "./Permissions/PermissionDataProvider";
import { DataProvider } from "react-admin";

export const combinedDataProvider = combineDataProviders(
  (resource: string): DataProvider => {
    switch (resource) {
      case "concepts":
        return ConceptDataProvider();
      case "users":
        return UserDataProvider();
      case "accessAreas":
        return AccessAreaDataProvider();
      case "tenantTypes":
        return TenantTypeDataProvider();
      case "tenants":
        return TenantDataProvider();
      case "permissions":
        return PermissionDataProvider();
      default:
        throw new Error(`Unknown resource: ${resource}`);
    }
  }
);
